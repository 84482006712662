/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import { useCallback, useEffect, useState } from 'react';
import { FaExpandArrowsAlt } from 'react-icons/fa';

import Button from '../Button';

interface FlashProps {
  active: boolean;
  setActive: (newVal: boolean) => void;
  frames: string[];
  timeUnit: number;
  fullScreen: boolean;
  setFullScreen: (newVal: boolean) => void;
}
let idx = 0;
let interval: NodeJS.Timeout | undefined;

function Flash({
  active,
  setActive,
  frames,
  timeUnit,
  fullScreen,
  setFullScreen,
}: FlashProps): JSX.Element {
  const [frame, setFrame] = useState(frames[idx]);

  const startNew = useCallback(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      if (idx < frames.length - 1) {
        idx += 1;
      } else {
        idx = 0;
      }
      setFrame(frames[idx]);
    }, timeUnit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeUnit, frames]);

  useEffect(() => {
    idx = 0;
    setFrame(frames[0]);
    if (active) {
      startNew();
    } else if (interval) {
      clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, timeUnit, frames]);

  return (
    <div
      className={` border-4 border-gray-300 ${
        fullScreen
          ? 'z-10 absolute top-0 right-0 left-0 bottom-0 bg-white'
          : 'relative flex flex-col w-full h-auto flex-grow min-h-[33%]'
      }`}
    >
      {active && (
        <Button
          id="full-screen"
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={() => {
            setFullScreen(!fullScreen);
            window.scrollTo({ top: 0 });
          }}
        >
          <FaExpandArrowsAlt className={fullScreen ? 'h-10 w-10' : 'h-8 w-8'} />
        </Button>
      )}
      <div
        tabIndex={0}
        role="button"
        aria-label="Flash"
        className={`w-full ${fullScreen ? 'h-full' : 'h-full flex-grow'} ${
          frame === '1' ? 'bg-white' : 'bg-black'
        }`}
        onClick={() => {
          if (fullScreen) {
            setActive(false);
            setFullScreen(false);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            setActive(false);
            setFullScreen(false);
          }
        }}
      />
    </div>
  );
}

export default Flash;
