import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import Card from '../Card';
import Transition from '../Transition';
import InfoImage from './info.png';

function InfoWrapper(): JSX.Element {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <Card className="items-start flex-col">
      <Button
        id="qrcode-toggle"
        className="flex w-full items-center text-lg"
        onClick={() => setIsToggled(!isToggled)}
      >
        {isToggled ? (
          <HiChevronDown className="h-5 w-5" />
        ) : (
          <HiChevronRight className="h-5 w-5" />
        )}
        <div className="flex flex-grow items-center px-2 gap-2">
          <FaInfoCircle className="h-4 w-4" />
          <FormattedMessage id="Instructions" />
        </div>
      </Button>
      <Transition show={isToggled} className="flex justify-center w-full">
        <img src={InfoImage} className="max-w-xl w-full" alt="info" />
      </Transition>
    </Card>
  );
}
export default InfoWrapper;
