export enum Configcode {
  cascade = '0000',
  reset = '0001',
  pirLux = '0010',
  timing = '0011',
  aaw = '0100',
  audio = '0101',
  luxValue = '0110',
  neo = '0111',
}

export enum PIRLuxJustHead {
  isJustLightHead = '0',
  wholeLamp = '1',
}

export enum PIRHalfAutomatic {
  deactivate = '0',
  activate = '1',
}

export enum GenericActivate {
  keep = '00',
  deactivate = '01',
  activate = '10',
}

export enum PIRSteckerschaltung {
  deactivate = '0',
  activate = '1',
}

export enum LuxValues {
  keep = '000',
  lux200 = '001',
  lux300 = '010',
  lux400 = '011',
  lux500 = '100',
  lux600 = '101',
  lux700 = '110',
  lux800 = '111',
}

export const luxConfigs = [
  {
    title: 'deactivate',
    value: null,
  },
  {
    title: '200 Lux',
    value: LuxValues.lux200,
  },
  {
    title: '300 Lux',
    value: LuxValues.lux300,
  },
  {
    title: '400 Lux',
    value: LuxValues.lux400,
  },
  {
    title: '500 Lux',
    value: LuxValues.lux500,
  },
  {
    title: '600 Lux',
    value: LuxValues.lux600,
  },
  {
    title: '700 Lux',
    value: LuxValues.lux700,
  },
  {
    title: '800 Lux',
    value: LuxValues.lux800,
  },
];

export enum NeoConfig {
  keep = '00',
  justRight = '01',
  justLeft = '10',
  both = '11',
}

export const neoPirConfigs = [
  {
    title: 'Only the right one',
    value: NeoConfig.justRight,
  },
  {
    title: 'Only the left one',
    value: NeoConfig.justLeft,
  },
  {
    title: 'Both',
    value: NeoConfig.both,
  },
];
