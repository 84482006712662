import { useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import DebugInformation from './components/DebugInformation';
import Flash from './components/Flash';
import Header, { Languages } from './components/Header';
import InfoWrapper from './components/InfoWrapper';
import PlayButton from './components/PlayButton';
import SwitchesWrapper from './components/SwitchesWrapper';

import Warnings from './components/Warnings';

import translations from './translations';
import getManchesterCoding from './utils/getManchesterCoding';

import './App.css';

function App() {
  const { REACT_APP_DEBUG_MODE } = process.env;
  const [language, setLanguage] = useState<Languages>(Languages.DE);
  const [startValue] = useState('111');
  const [isSignalActive, setIsSignalActive] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [timeUnit, setTimeUnit] = useState(150);
  const [middleValue, setMiddleValue] = useState(['0000000000']);

  const manchesterCoded = useMemo(
    () => getManchesterCoding(startValue, middleValue),
    [startValue, middleValue],
  );

  return (
    <IntlProvider locale={language} messages={translations[language]}>
      <div className="flex flex-col min-h-screen bg-gray-50 h-full text-gray-800">
        <Header language={language} setLanguage={setLanguage} />
        <div
          className={`flex flex-grow flex-col mb-4 ${
            fullScreen ? 'overflow-y-hidden' : ''
          }`}
        >
          <InfoWrapper />
          {REACT_APP_DEBUG_MODE === 'true' && (
            <DebugInformation
              timeUnit={timeUnit}
              setTimeUnit={setTimeUnit}
              flashValue={`${startValue}${middleValue.join('')}`}
              manchesterCoded={manchesterCoded}
            />
          )}
          <SwitchesWrapper setMiddleValue={setMiddleValue} />
          <Warnings />
          <PlayButton
            active={isSignalActive}
            setActive={(val) => {
              setIsSignalActive(val);
              if (val) {
                setFullScreen(true);
              }
            }}
            disabled={
              middleValue.length === 1 && middleValue[0] === '0000000000'
            }
          />
          <Flash
            active={isSignalActive}
            setActive={setIsSignalActive}
            timeUnit={timeUnit}
            frames={[
              ...Array.from(getManchesterCoding(startValue, middleValue)),
            ]}
            fullScreen={fullScreen}
            setFullScreen={setFullScreen}
          />
        </div>
      </div>
    </IntlProvider>
  );
}

export default App;
