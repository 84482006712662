import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { HiCheck, HiSelector } from 'react-icons/hi';
import { LuxValues, NeoConfig } from '../SwitchesWrapper/bitTypes';

export interface ListBoxElement {
  title: string;
  value: number | NeoConfig | LuxValues | null;
}

interface ListboxProps {
  options: ListBoxElement[];
  selected: ListBoxElement;
  setSelected: (selected: ListBoxElement) => void;
  color: string;
  tickColor: string;
  formatTitle?: (selected: ListBoxElement) => string | JSX.Element;
}

export default function ListboxComp({
  options,
  selected,
  setSelected,
  formatTitle = (s) => s.title,
  color,
  tickColor,
}: ListboxProps): JSX.Element {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative mt-1 w-full">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-lg">
          <span className="block">{formatTitle(selected)}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((el, idx) => (
              <Listbox.Option
                // eslint-disable-next-line react/no-array-index-key
                key={`key-${idx}`}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? color : 'text-gray-900'
                  }`
                }
                value={el}
              >
                {({ selected: sel }) => (
                  <>
                    <span
                      className={`block ${sel ? 'font-medium' : 'font-normal'}`}
                    >
                      {formatTitle(el)}
                    </span>
                    {sel ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${tickColor}`}
                      >
                        <HiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
