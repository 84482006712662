import { Switch as HUISwitch } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';

interface SwitchProps {
  isEnabled?: boolean;
  onSetEnable: (val: boolean) => void;
  className?: string;
  color?: string;
  disabled?: boolean;
}

export default function Switch({
  isEnabled = false,
  onSetEnable,
  className = '',
  color = 'bg-yellow-500',
  disabled = false,
}: SwitchProps): JSX.Element {
  const [enabled, setEnabled] = useState(isEnabled);

  useEffect(() => {
    onSetEnable(isEnabled);
    setEnabled(isEnabled);
  }, [isEnabled, onSetEnable]);

  const switchColor = useMemo(() => {
    if (disabled) {
      return 'bg-gray-200';
    }
    return enabled ? color : 'bg-gray-300';
  }, [disabled, enabled, color]);
  return (
    <HUISwitch
      checked={enabled}
      onChange={(val) => {
        if (disabled) {
          return;
        }
        onSetEnable(val);
        setEnabled(val);
      }}
      className={`${switchColor} relative inline-flex items-center h-6 rounded-full w-11 ${className}`}
    >
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } transition-all inline-block w-4 h-4 transform bg-white rounded-full`}
      />
    </HUISwitch>
  );
}
