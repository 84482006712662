import { useEffect, useState } from 'react';
import { FaCog, FaRegUser, FaUndo } from 'react-icons/fa';
import {
  HiOutlineCheckCircle,
  HiOutlineClock,
  HiOutlineCog8Tooth,
  HiOutlineSun,
  HiOutlineXCircle,
} from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import Card from '../Card';
import Listbox from '../Listbox';
import { ListBoxElement } from '../Listbox/Listbox';
import Switch from '../Switch';
import {
  Configcode,
  GenericActivate,
  LuxValues,
  NeoConfig,
  PIRHalfAutomatic,
  PIRLuxJustHead,
  PIRSteckerschaltung,
  luxConfigs,
  neoPirConfigs,
} from './bitTypes';

interface SwitchesWrapperProps {
  setMiddleValue: (newMiddleValue: string[]) => void;
}

type ObjectType = { [key: string]: string };

const getEvenedBit = (str: string) => {
  const arr = str.split('').map((s) => parseInt(s, 10));
  let countOnes = 0;
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === 1) {
      countOnes += 1;
    }
  }
  const moduloEight = countOnes % 8;
  let parity;
  if (moduloEight === 0) {
    parity = '000';
  }
  if (moduloEight === 1) {
    parity = '001';
  }
  if (moduloEight === 2) {
    parity = '010';
  }
  if (moduloEight === 3) {
    parity = '011';
  }
  if (moduloEight === 4) {
    parity = '100';
  }
  if (moduloEight === 5) {
    parity = '101';
  }
  if (moduloEight === 6) {
    parity = '110';
  }
  if (moduloEight === 7) {
    parity = '111';
  }
  return `${str}${parity}`;
};

const modes = [
  {
    title: 'Full automatic', // PIR Aktive, Steckershaltung off, Halbautomatic off
    value: 1,
  },
  {
    title: 'Half automatic', // PIR Aktive, Steckershaltung off, Halbautomatic on
    value: 2,
  },
  {
    title: 'Manual', // PIR off, Steckershaltung off, Halbautomatic on
    value: 3,
  },
  {
    title: 'Plug mode', // PIR off, Steckershaltung on, Halbautomatic off
    value: 4,
  },
];

const followUpTimes = [
  {
    title: '10',
    value: 10,
  },
  {
    title: '30',
    value: 30,
  },
  {
    title: '60',
    value: 60,
  },
  {
    title: '120',
    value: 120,
  },
];

const followUpBytes = {
  10: '00',
  30: '01',
  60: '10',
  120: '11',
} as ObjectType;

const formatTitleFallback = (e: ListBoxElement) => (
  <FormattedMessage id={e.title} />
);

const cascadeBits = {
  0: '000',
  1: '001',
  2: '010',
  3: '011',
  4: '100',
  5: '101',
  6: '110',
  7: '111',
} as ObjectType;

function SwitchesWrapper({
  setMiddleValue,
}: SwitchesWrapperProps): JSX.Element {
  const intl = useIntl();

  const [changeModeActive, setChangeModeActive] = useState(false);
  const [mode, setMode] = useState<ListBoxElement>(modes[0]);

  const [changePIRActive] = useState(false);
  const [neoPIRMode] = useState<ListBoxElement>(neoPirConfigs[2]);

  const [changeBrightnessActive, setChangeBrightnessActive] = useState(false);
  const [luxValue, setLuxValue] = useState<ListBoxElement>(luxConfigs[4]);
  const isBrightnessActive = changeBrightnessActive && !!luxValue?.value;

  // const [isPlugModeActive, setIsPlugModeActive] = useState(false);
  const [isJustLightHead] = useState(false);

  const [changeIsAloneAtWork, setChangeIsAloneAtWork] = useState(false);
  const [isAloneAtWork, setIsAloneAtWork] = useState(true);

  const [changeFollowUpTime, setChangeFollowUpTime] = useState(false);
  const [followUpTime, setFollowUpTime] = useState<ListBoxElement>(
    followUpTimes[0],
  );

  const [resetAllSettings, setResetAllSettings] = useState(false);

  // only active when the fourth mode is selected (Steckerschaltung)
  const isPlugModeActive = mode.value === 4;
  // only active when the second mode is selected (Halbautomatisch)
  const isHalfAutomatic = mode.value === 2;
  /*
  const [
    newInitialization,
    setNewInitialization
  ] = useState(false);
  */

  useEffect(() => {
    const bitsArray = [];
    if (
      resetAllSettings // || newInitialization
    ) {
      bitsArray.push(
        `${Configcode.reset}${
          '0' // newInitialization ? '1' : '0'
        }${resetAllSettings ? '1' : '0'}`,
      );
    }

    if (changeModeActive || changeBrightnessActive) {
      // Only Light head - bit 0
      const lightHead = isJustLightHead
        ? PIRLuxJustHead.isJustLightHead
        : PIRLuxJustHead.wholeLamp;

      // PIR sensor activation - bit 1-2
      const getLuxSensor = () => {
        if (changeModeActive && !isPlugModeActive) {
          return [1, 2].includes(mode.value as number) // full/half automatic mode
            ? GenericActivate.activate
            : GenericActivate.deactivate;
        }
        return GenericActivate.keep;
      };

      // Regulation - bit 3-4
      const brightnessString = isBrightnessActive
        ? GenericActivate.activate
        : GenericActivate.deactivate;
      const regulation =
        changeBrightnessActive && !isPlugModeActive
          ? brightnessString
          : GenericActivate.keep;

      // Steckerschaltung - bit 5
      const isPluggedString = isPlugModeActive
        ? PIRSteckerschaltung.activate
        : PIRSteckerschaltung.deactivate;

      // Half automatic - bit 6
      const halfAutomatic = isHalfAutomatic
        ? PIRHalfAutomatic.activate
        : PIRHalfAutomatic.deactivate;

      const pirString = `${
        Configcode.pirLux
      }${halfAutomatic}${isPluggedString}${regulation}${getLuxSensor()}${lightHead}`;

      bitsArray.push(pirString);
    }

    if (changeFollowUpTime && followUpTime?.value) {
      bitsArray.push(
        `${Configcode.timing}${followUpBytes[followUpTime.value]}`,
      );
    }

    if (changeIsAloneAtWork) {
      const isAloneBit = isAloneAtWork
        ? GenericActivate.activate
        : GenericActivate.deactivate;
      bitsArray.push(
        `${Configcode.aaw}${
          changeIsAloneAtWork ? isAloneBit : GenericActivate.keep
        }`,
      );
    }

    if (isBrightnessActive) {
      const luxValueString = `${Configcode.luxValue}${luxValue.value}`;
      bitsArray.push(luxValueString);
    }

    if (changePIRActive) {
      bitsArray.push(
        `${Configcode.neo}${
          changePIRActive ? neoPIRMode.value : NeoConfig.keep
        }`,
      );
    }

    const cascadeString = `${Configcode.cascade}${
      cascadeBits[bitsArray.length || 0]
    }`;
    bitsArray.push(cascadeString);

    const evenedBits = bitsArray.map((b) => getEvenedBit(b));
    setMiddleValue(evenedBits);
  }, [
    resetAllSettings,
    isPlugModeActive,
    changeBrightnessActive,
    changeFollowUpTime,
    followUpTime,
    changeIsAloneAtWork,
    isBrightnessActive,
    isAloneAtWork,
    setMiddleValue,
    changeModeActive,
    mode.value,
    isJustLightHead,
    isHalfAutomatic,
    changePIRActive,
    neoPIRMode,
    luxValue,
  ]);
  interface Switch {
    name: string;
    isEnabled: boolean;
    onSetEnable: (val: boolean) => void;
    icon: JSX.Element;
    color: {
      text: string;
      color: string;
      fill: string;
      border: string;
    };
    disabled: boolean;
    subSelect?: {
      value: ListBoxElement;
      onValueChange: (e: ListBoxElement) => void;
      options: {
        title: string;
        value: number | LuxValues;
      }[];
      color: string;
      tickColor: string;
      formatTitle?: (e: ListBoxElement) => string;
    };
    subSwitch?: {
      name: string;
      isEnabled: boolean;
      onSetEnable: (val: boolean) => void;
    };
  }
  const switches = [
    /*
    {
      name: 'Change presence sensor',
      isEnabled: changePIRActive,
      onSetEnable: (val: boolean) => setChangePIRActive(val),
      icon: <HiOutlineStatusOnline className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-blue-500',
        color: 'bg-blue-500',
        fill: '#3b82f6',
        border: 'border-blue-400 focus:border-blue-500',
      },
      disabled: newInitialization || resetAllSettings || isPlugModeActive,
      subSwitch: {
        name: 'Activate presence sensor',
        isEnabled: isPIRActive,
        onSetEnable: (val: boolean) => setIsPIRActive(val),
      },
    },
    */
    {
      name: 'Change mode',
      isEnabled: changeModeActive,
      onSetEnable: (val: boolean) => setChangeModeActive(val),
      icon: <HiOutlineCog8Tooth className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-emerald-500',
        color: 'bg-emerald-500',
        fill: '#10b981',
        border: 'border-emerald-400 focus:border-emerald-500',
      },
      disabled: resetAllSettings, // || newInitialization,
      subSelect: {
        value: mode,
        onValueChange: (e: ListBoxElement) => setMode(e),
        options: modes,
        color: 'bg-emerald-100 text-emerald-900',
        tickColor: 'text-emerald-600',
      },
    },
    {
      name: 'Change brightness sensor',
      isEnabled: changeBrightnessActive,
      onSetEnable: (val: boolean) => setChangeBrightnessActive(val),
      icon: <HiOutlineSun className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-violet-500',
        color: 'bg-violet-500',
        fill: '#8b5cf6',
        border: 'border-violet-400 focus:border-violet-500',
      },
      disabled: resetAllSettings || isPlugModeActive,
      subSelect: {
        value: luxValue,
        onValueChange: (e: ListBoxElement) => setLuxValue(e),
        options: luxConfigs,
        color: 'bg-red-100 text-red-900',
        tickColor: 'text-red-600',
      },
    },
    {
      name: 'Change follow-up time',
      isEnabled: changeFollowUpTime,
      onSetEnable: (val: boolean) => setChangeFollowUpTime(val),
      icon: <HiOutlineClock className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-yellow-500',
        color: 'bg-yellow-500',
        fill: '#eab308',
        border: 'border-yellow-400 focus:border-yellow-500',
      },
      disabled: resetAllSettings, // || newInitialization
      subSelect: {
        value: followUpTime,
        onValueChange: (e: ListBoxElement) => setFollowUpTime(e),
        options: followUpTimes,
        color: 'bg-amber-100 text-amber-900',
        tickColor: 'text-amber-600',
        formatTitle: (e: ListBoxElement) =>
          intl.formatMessage(
            {
              id:
                e.value && e.value > 1
                  ? 'followUpTime-plural'
                  : 'followUpTime-singular',
            },
            { time: e.value },
          ),
      },
    },
    /*
    {
      name: 'Plug mode',
      isEnabled: isPlugModeActive,
      onSetEnable: (val: boolean) => setIsPlugModeActive(val),
      icon: <FaPlug className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-gray-500',
        color: 'bg-gray-500',
        fill: '#6b7280',
        border: 'border-gray-400 focus:border-gray-500',
      },
      disabled: newInitialization || resetAllSettings,
    },
    */
    {
      name: 'Change Alone@Work',
      isEnabled: changeIsAloneAtWork,
      onSetEnable: (val: boolean) => setChangeIsAloneAtWork(val),
      icon: <FaRegUser className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-blue-500',
        color: 'bg-blue-500',
        fill: '#3b82f6',
        border: 'border-blue-400 focus:border-blue-500',
      },
      disabled: resetAllSettings, // || newInitialization
      subSwitch: {
        name: 'Activate Alone@Work',
        isEnabled: isAloneAtWork,
        onSetEnable: (val: boolean) => setIsAloneAtWork(val),
      },
    },
    /*
    {
      name: 'Nachlaufzeit',
      isEnabled: keepNachlaufzeit,
      onSetEnable: (val: boolean) => setKeepNachlaufzeit(val),
      icon: <FaHistory className="h-4 w-4 mx-1 my-2" />,
      color: {
        text: 'text-gray-500',
        color: 'bg-gray-500',
        fill: '#6b7280',
        border: 'border-gray-400 focus:border-gray-500',
      },
      disabled: resetAllSettings,
    },
    */
    {
      name: 'Reset to standard settings',
      isEnabled: resetAllSettings,
      onSetEnable: (val: boolean) => setResetAllSettings(val),
      icon: <FaUndo className="h-4 w-4 mx-1 my-2" />,
      color: {
        text: 'text-gray-500',
        color: 'bg-gray-500',
        fill: '#6b7280',
        border: 'border-gray-400 focus:border-gray-500',
      },
      // disabled: newInitialization,
    },
    /*
    {
      name: 'New initialization',
      isEnabled: newInitialization,
      onSetEnable: (val: boolean) => setNewInitialization(val),
      icon: <FaSync className="h-4 w-4 m-2" />,
      color: {
        text: 'text-red-500',
        color: 'bg-red-500',
        fill: '#ef4444',
        border: 'border-red-400 focus:border-red-500',
      },
      disabled: resetAllSettings,
    },
    */
  ].filter((s) => s) as Switch[];

  return (
    <Card className="flex-col">
      <div className="flex flex-grow items-center px-2 gap-2 text-lg">
        <FaCog className="h-4 w-4" />
        <FormattedMessage id="Configuration" />
      </div>
      <div className="flex flex-col gap-2 p-2">
        {switches.map((sw) => {
          if (!sw) {
            return null;
          }
          const {
            disabled,
            color,
            subSwitch,
            subSelect,
            name,
            isEnabled,
            onSetEnable,
            icon,
          } = sw;
          return (
            <div className="flex flex-col gap-2" key={sw.name}>
              <div className="flex items-center gap-2 justify-between">
                <div className="flex items-center gap-2">
                  <Button
                    id={name}
                    className={`flex items-center justify-center w-12 h-12 bg-white shadow-lg text-aqua rounded-xl ring-1 ring-black/5 shadow-black/10 ${
                      disabled ? 'text-gray-200' : color.text
                    }`}
                    onClick={() => {
                      onSetEnable(!isEnabled);
                    }}
                  >
                    {icon}
                  </Button>
                  <FormattedMessage id={name} />
                </div>
                <Switch
                  isEnabled={isEnabled}
                  onSetEnable={onSetEnable}
                  color={color.color}
                  disabled={disabled}
                />
              </div>
              {subSelect && isEnabled && (
                <div className="flex items-center gap-2 pl-14">
                  <Listbox
                    options={subSelect.options}
                    selected={subSelect.value}
                    setSelected={subSelect.onValueChange}
                    formatTitle={subSelect.formatTitle || formatTitleFallback}
                    color={subSelect.color}
                    tickColor={subSelect.tickColor}
                  />
                </div>
              )}
              {subSwitch && isEnabled && (
                <div className="flex items-center gap-2 pl-14">
                  <HiOutlineXCircle
                    className={`h-6 w-6 ${color.text}`}
                    title={intl.formatMessage({
                      id: 'Deactivate',
                    })}
                  />
                  <Switch
                    isEnabled={subSwitch.isEnabled}
                    onSetEnable={subSwitch.onSetEnable}
                    color={color.color}
                    disabled={disabled}
                  />
                  <HiOutlineCheckCircle
                    className={`h-6 w-6 ${color.text}`}
                    title={intl.formatMessage({
                      id: 'Activate',
                    })}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
}
export default SwitchesWrapper;
