import { FaRegPauseCircle, FaRegPlayCircle } from 'react-icons/fa';

import Button from '../Button';

interface PlayButtonProps {
  active: boolean;
  setActive: (active: boolean) => void;
  disabled: boolean;
}

function PlayButton({
  active,
  setActive,
  disabled,
}: PlayButtonProps): JSX.Element {
  return (
    <div className="flex justify-center w-full pb-2">
      <Button
        id="close-photosensitive-warning"
        className="flex items-center"
        onClick={() => setActive(!active)}
        disabled={disabled}
      >
        {active ? (
          <FaRegPauseCircle className="h-12 w-12" />
        ) : (
          <FaRegPlayCircle className="h-12 w-12" />
        )}
      </Button>
    </div>
  );
}

export default PlayButton;
