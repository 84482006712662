import { ElementType } from 'react';
import { Transition as HUITransition } from '@headlessui/react';

interface TransitionProps {
  className?: string;
  show: boolean;
  children: React.ReactNode;
  as?: ElementType<any> | undefined;
}

export default function Transition({
  className,
  show,
  children,
  as,
}: TransitionProps): JSX.Element {
  return (
    <HUITransition
      show={show}
      as={as}
      className={className}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </HUITransition>
  );
}
