interface ButtonProps {
  id: string;
  children?: React.ReactNode;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  title?: string;
}

export default function Button({
  id,
  children,
  onClick,
  className = '',
  title,
  disabled = false,
}: ButtonProps): JSX.Element {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      onKeyPress={(e) => e.which === 13 && onClick}
      className={`${className} transition-all disabled:opacity-50 disabled:cursor-default`}
      title={title}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
